import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Swiper, Billboard, H1, Button } from '@raysync/common/components'
import { BannerContainer, BannerWrap } from './atoms'
import { useMedia } from '@raysync/common/hooks'

export const Banner = props => {
  const isMobile = useMedia()
  return (
    <StaticQuery
      query={graphql`
        query {
          banner: file(relativePath: { regex: "/agent/banner_3.png/" }) {
            ...fluidImage
          }
          mobileBanner: file(relativePath: { regex: "/agent/banner_3_m.png/" }) {
            ...fluidImage
          }
        }
      `}
      render={data => {
        const bannerImage = isMobile ? data.mobileBanner.childImageSharp : data.banner.childImageSharp
        return (
          <Swiper>
            <BannerContainer>
              <BannerWrap>
                <span>镭速合作伙伴计划</span>
                <H1>加入我们共享数据通道红利</H1>
                <p>
                  我们已经与众多优秀企业达成了友好稳定的合作关系。我们携手丰富多样的生态伙伴，通过整合双方优势，凝聚多方智慧和资源，为客户提供优质产品与服务，恪守共赢，共同创造无限可能。
                </p>
                <Button size='large' onClick={props.onClick}>
                  成为合作伙伴
                </Button>
              </BannerWrap>
              <Billboard fluid={bannerImage.fluid} />
            </BannerContainer>
          </Swiper>
        )
      }}
    />
  )
}
