import React from 'react'
import { H2, H3, Wrapper, Button, Modal, Swiper } from '@raysync/common/components'
import { useMedia } from '@raysync/common/hooks'
import { Navigation } from '@raysync/common/services'
import Layout from '../../components/layout'
import { InviteBanner } from '../../components/banners'
import {
  SectionWrap,
  SubTitle,
  ApplySection,
  ProcessWrapper,
  ProcessList,
  ProcessItem,
  SupportList,
  SupportItem,
  SupportHeader,
  SupportContent,
  StepDiv,
} from './atoms'
import { Banner } from './banner'
import ApplyContent from './apply-content'
import Img1 from './img1.png'
import Support1 from './support1.png'
import Support2 from './support2.png'
import Support3 from './support3.png'
import Support4 from './support4.png'
import Process1 from './process1.png'
import Process2 from './process2.png'
import Process3 from './process3.png'
import Process4 from './process4.png'
import Process5 from './process5.png'

const processList = [
  {
    step: '第一步',
    title: '提交表单申请',
    img: Process1,
  },
  {
    step: '第二步',
    title: '官网信息审核',
    img: Process2,
  },
  {
    step: '第三步',
    title: '合作洽谈',
    img: Process3,
  },
  {
    step: '第四步',
    title: '签约',
    img: Process4,
  },
  {
    step: '第五步',
    title: '成为合作伙伴',
    img: Process5,
  },
]

const supportList = [
  {
    title: '推广支持',
    desc: (
      <>
        <p>1. 渠道资源优先权益</p>
        <p>2. 镭速官网优先展示特权</p>
        <p>3. 镭速媒体渠道推介活动</p>
        <p>4. 公司资源共享支持</p>
      </>
    ),
    img: Support1,
  },
  {
    title: '商务支持',
    desc: (
      <>
        <p>1. 专属商务团队支持</p>
        <p>2. 共享商机线索</p>
        <p>3. 项目报备保护</p>
        <p>4. 伙伴政策激励计划</p>
        <p>5. 业务培训赋能</p>
        <p>6. 在线客服答疑</p>
      </>
    ),
    img: Support2,
  },
  {
    title: '市场支持',
    desc: (
      <>
        <p>1. 市场宣传支持 </p>
        <p>2. 市场活动支持 </p>
        <p>3. 活动物料支持</p>
        <p>4. 生态合作推介会</p>
      </>
    ),
    img: Support3,
  },
  {
    title: '技术支持',
    desc: (
      <>
        <p>1. 专家顾问指导 </p>
        <p>2. 技术培训认证</p>
        <p>3. 技术文档支持</p>
        <p>4. 现场技术支持</p>
        <p>5. 技术主题研讨会</p>
        <p>6. 资深专家案例分享</p>
      </>
    ),
    img: Support4,
  },
]

const Partners = ({ location }) => {
  const isMobile = useMedia()
  const [visible, setVisible] = React.useState(false)
  const handleClick = () => {
    if (isMobile) {
      Navigation.to({ link: '/partners/apply' })
    } else {
      setVisible(true)
    }
  }
  return (
    <Layout pageType='agent' location={location} showH1={false}>
      <Banner onClick={handleClick} />
      <SectionWrap>
        <img src={Img1} alt='why choose raysync?' />
        <div>
          <SubTitle>为什么选择镭速</SubTitle>
          <H2>激活数据能量，建设数据新通道</H2>
          <p>
            镭速专注于大数据传输领域，为企业提供文件高速传输与文件内外网交换、数据迁移等一站式解决方案，致力于帮助各种机构在大数据时代实现更加高效的数据同步和信息互动，我们的产品与服务已经获得多家企业的认可与赞扬。
          </p>
          <p>
            加入镭速生态合作伙伴计划，我们向您承诺建立长期的合作伙伴关系。作为一家服务全国数据传输领域的领先企业，我们以双方共同发展繁荣为目标，与您一起建立“互生、共生、再生”的产业环境和积极稳定的商业生态体系。
          </p>
          <p>在我们的支持和优质服务下，您将获取更多资源和声誉，创造价值、加速发展，更好地满足客户的多样化需求。</p>
        </div>
      </SectionWrap>
      <ApplySection>
        <Wrapper>
          <SubTitle>合作伙伴权益及申请流程</SubTitle>
          <H2>加入镭速，合作互赢，共建生态 </H2>
          <p>为合作伙伴提供：</p>
          <SupportList>
            {supportList.map(support => (
              <SupportItem key={support.title}>
                <SupportHeader>
                  <span>权益</span>
                  <H3>{support.title}</H3>
                  <img src={support.img} alt={support.title} />
                </SupportHeader>
                <SupportContent>{support.desc}</SupportContent>
              </SupportItem>
            ))}
          </SupportList>
          <p>成为合作伙伴申请流程：</p>
          <ProcessWrapper>
            <ProcessList>
              {processList.map((process, index) => (
                <React.Fragment key={index}>
                  <ProcessItem>
                    <img src={process.img} alt={process.title} />
                    <p>{process.step}</p>
                    <h4>{process.title}</h4>
                  </ProcessItem>
                  {index !== processList.length - 1 ? <StepDiv /> : null}
                </React.Fragment>
              ))}
            </ProcessList>
            <Button size='large' onClick={handleClick}>
              申请加入
            </Button>
            <Modal
              visible={visible}
              top='60px'
              minWidth={'800px'}
              closable
              onClose={() => {
                setVisible(false)
              }}
            >
              <ApplyContent
                closeModal={() => {
                  setVisible(false)
                }}
              />
            </Modal>
          </ProcessWrapper>
        </Wrapper>
      </ApplySection>
      <Swiper>
        <InviteBanner type='agent' onApplyClick={handleClick} hasDesc={false} />
      </Swiper>
    </Layout>
  )
}

export default Partners
